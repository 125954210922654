import { useEffect, useRef } from "react";
import { createGlobalStyle } from "styled-components";
import { API_URL } from "./const";
import { useAppContext } from "./Context/AppContext";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { message } from "antd";
import HomePage from "./Pages/HomePage/HomePage";
import LoginPage from "./Pages/LoginPage/LoginPage";
import FaqPage from "./Pages/FaqPage/FaqPage";
import BugReportPage from "./Pages/BugReportPage/BugReportPage";
import CollectionsPage from "./Pages/CollectionsPage/CollectionsPage";
import ProtectedRoute from "./ProtectedRoute";
import MyCollections from "./Pages/MyCollectionsPage/MyCollectionsPage";
import ModuleContent from "./Components/ModuleContent";
import VideoPage from "./Components/VideoPage";
import Loader from "./Components/Loader/Loader";
import WalletPage from "./Pages/WalletPage/WalletPage";
import { User } from "./Models/User";
import AuthorProfilePage from "./Pages/AuthorProfilePage/AuthorProfilePage";
import PageNotFoundPage from "./Pages/PageNotFoundPage/PageNotFoundPage";
import CourseCertificationPage from "./Pages/CourseCertificatePage/CourseCertificatePage";

const Main: React.FC = () => {
    const dataFetchedRef = useRef(true);
    const {
        setShowLoader,
        setAuthenticate,
        setCollections,
        setCollectionsLoaded,
        setLoadingApp,
        setUser,
        loadingApp,
        showLoader
    } = useAppContext();



    const clearAuthentication = () => {
        localStorage.removeItem("refresh");
        localStorage.removeItem("access");
        localStorage.removeItem("userId");
        setAuthenticate(false);
    };

    const fetchJWTTokens = () => {
        fetch(`${API_URL}/accounts/jwt-tokens/`, {
            method: "GET",
            credentials: "include",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                "Sec-Fetch-Site": "same-site"
            }
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data) => {
                localStorage.setItem("refresh", data["refresh"]);
                localStorage.setItem("access", data["access"]);
                fetchUserData();
            })
            .catch((error) => {
                clearAuthentication();
                setLoadingApp(false);
                setShowLoader(false);

                // Fetch collections on background (Even not logged in)
                fetchCollections();
            });
    };

    const fetchUserData = () => {
        fetch(API_URL + `/api/v1/users/`, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + localStorage.getItem("access"),
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then((response: any) => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(response.status);
                }
            })
            .then((data: User) => {
                localStorage.setItem("userId", data.id.toString());
                setAuthenticate(true);
                setLoadingApp(false);
                setShowLoader(false);
                setUser(data);

                // Fetch collections on background
                fetchCollections();
            })
            .catch((error) => {
                if (error === "TypeError: Failed to fetch") {
                    message.error(
                        "Unable to reach the server right now. Please try again later."
                    );
                    onAuthenticationFail();
                }
                if (parseInt(error.message) === 401) {
                    getNewAccessToken();
                } else {
                    onAuthenticationFail();
                }
            });
    };

    const onAuthenticationFail = () => {
        clearAuthentication();
        setLoadingApp(false);
        setShowLoader(false);
    }

    const getNewAccessToken = () => {
        const refreshToke = localStorage.getItem("refresh");
        clearAuthentication();
        return fetch(API_URL + `/api/v1/token/refresh/`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ refresh: refreshToke })
        })
            .then((response) => {
                if (response.ok) {
                    return response.json();
                } else {
                    onAuthenticationFail();
                }
            })
            .then((data) => {
                if (data != null) {
                    localStorage.setItem("access", data["access"]);
                    fetchUserData();
                }
            })
            .catch((error) => {
                if (parseInt(error.message) === 401) {
                    onAuthenticationFail();
                    localStorage.removeItem("refresh");
                    message.info(
                        "Your session has timed out. Please log in again."
                    );
                }
            });
    };

    const fetchCollections = () => {
        fetch(API_URL + `/api/v1/collections/`, {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json"
            }
        })
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setCollections(data);
                setCollectionsLoaded(true);
            })
            .catch(() => {
                setCollectionsLoaded(false);
            });
    };

    const onPageLoad = () => {
        setLoadingApp(true);
        setShowLoader(true);
        if (localStorage.getItem("access")) {
            fetchUserData();
        } else {
            fetchJWTTokens();
        }
    };

    const GlobalStyle = createGlobalStyle``;

    useEffect(() => {
        if (dataFetchedRef.current) {
            dataFetchedRef.current = false;
            onPageLoad();
        }
    }, [dataFetchedRef.current]);


    return (
        <div className="App">
            <GlobalStyle />
            {!loadingApp && (
                <>
                    <BrowserRouter>
                        <Routes>
                            {/* Public routes */}
                            <Route path="/" element={<HomePage />} />
                            <Route
                                path="/login/"
                                element={<LoginPage />}
                            />
                            <Route
                                path="/faq"
                                element={<FaqPage />}
                            />
                            <Route
                                path="/bugreport"
                                element={<BugReportPage />}
                            />
                            <Route
                                path="/collections"
                                element={<CollectionsPage />}
                            />

                            <Route
                                path="/collections/:collectionId"
                                element={<ModuleContent />}
                            />

                            <Route
                                path="/collections/:collectionId/certification"
                                element={<CourseCertificationPage />}
                            />

                            {/* Protected route: start */}
                            <Route
                                path="/my-collections"
                                element={
                                    <ProtectedRoute
                                        element={
                                            <MyCollections />
                                        }
                                    />
                                }
                            />

                            <Route
                                path="/wallet"
                                element={
                                    <ProtectedRoute
                                        element={
                                            <WalletPage />
                                        }
                                    />
                                }
                            />

                            <Route
                                path="/collections/:collectionId/modules/:moduleNumber/videos/:videoNumber"
                                element={
                                    <ProtectedRoute
                                        element={<VideoPage />}
                                    />
                                }
                            />
                            {/* Protected route: end */}

                            <Route
                                path="/404/"
                                element={<PageNotFoundPage />}
                            />

                            <Route
                                path="/:authorSlug"
                                element={<AuthorProfilePage />}
                            />

                        </Routes>
                    </BrowserRouter>
                </>
            )}
            {showLoader && <Loader />}
        </div>
    )
}

export default Main;
