import { useEffect } from "react";
import Loader from "../Loader/Loader";
import { WEBSITE_DOMAIN } from "../../const";

const RedirectToWeb = () => {
    useEffect(() => {
        const currentUrl = window.location.href;
        const webUrl = currentUrl.replace(window.location.hostname, WEBSITE_DOMAIN);
        window.location.replace(webUrl);
    }, []);

    return (
        <div>
            <Loader />
        </div>
    );
};

export default RedirectToWeb;