import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { isMobile } from "react-device-detect";
import RedirectToWeb from "./Components/RedirectToWeb/RedirectToWeb";
import { IS_PROD } from "./const";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
    <React.StrictMode>
        {IS_PROD && !isMobile ? <RedirectToWeb /> : <App />}
    </React.StrictMode>
);

reportWebVitals(false);
